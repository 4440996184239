let ANLIZE_SVG={}
ANLIZE_SVG.polygonCount=0;
ANLIZE_SVG.drawCount=0;

class AnalizeSVG{

	//パスが交差すると、正しく表示されないので、illustratorのパスファインダーで、中窓を使って分割する。
	//複合パスは、穴のパスが外側のパスの内部に完全に含まれている場合のみ正しく描画される。
	//WebGLRendererの場合、離れたパスの複合パス化は表示がおかしくなる。
	//PIXIは、lineToで、前と同じ場所に線を引くと、そこで描画が終了してしまうバグがある
	
	constructor(_loadSVG,_endFunc){
		const container=new PIXI.Container();
		
		$.ajax({
	        type: "GET",
	        dataType:"xml",//dataTypeを指定する
	        url:_loadSVG,
	        success: function(data) {
	            //console.log(data);
	            analizeDom(data);
	            _endFunc();
	        }
		});

		this.getContainer=()=>{
			return container;
		}

		this.getBounds=()=>{
			return boundingBoxs;
		}


		let drawColor=0xcb322b;
		var lineStyle={w:2,color:drawColor,opacity:1}
		let drawOpacity=1;
		let boundingBoxs=[];

	

		function analizeDom(_dom){
			let jObj=$("rect,circle,ellipse,polygon,path",_dom);
			ANLIZE_SVG.drawCount+=1;

			jObj.each(function(id,el){
				if(el.nodeName=="rect"){
					drawRect($(el));
				}else if(el.nodeName=="circle"){
					drawCircle($(el));
				}else if(el.nodeName=="ellipse"){
					drawEllipse($(el));
				}else if(el.nodeName=="polygon"){
					drawPolygon($(el));
				}else if(el.nodeName=="path"){
					drawPath($(el));
				}
			})
			
		}

		function drawRect(_jObjRects){
			
			$.each(_jObjRects,(id,el)=>{
				let sX=Number($(el).attr("x"));
				let sY=Number($(el).attr("y"));
				
				if(String($(el).attr("x"))=='undefined'){
					sX=0;
				}
				if(String($(el).attr("y"))=='undefined'){
					sY=0;
				}
				
				let w=Number($(el).attr("width"));
				let h=Number($(el).attr("height"));
				let rectGraphic=new PIXI.Graphics();
				rectGraphic.lineStyle(lineStyle.w,drawColor,1);
				rectGraphic.beginFill(drawColor,drawOpacity);

				rectGraphic.drawRect(sX,sY,w,h);
				container.addChild(rectGraphic);
				


				
				var bounds=new PIXI.Bounds();
				
				boundingBoxs.push(rectGraphic.getBounds());
     
			})
		}

		function drawCircle(_jObjCircle){
			
			$.each(_jObjCircle,(id,el)=>{
				let cX=Number($(el).attr("cx"));
				let cY=Number($(el).attr("cy"));
				let cR=Number($(el).attr("r"));
				let circleGraphic=new PIXI.Graphics();
				circleGraphic.lineStyle(lineStyle.w,drawColor,1);
				circleGraphic.beginFill(drawColor,drawOpacity);
				circleGraphic.drawCircle(cX,cY,cR);
				container.addChild(circleGraphic);
				boundingBoxs.push(circleGraphic.getBounds())
			})
		}

		function drawEllipse(_jObjEllipse){
			
			$.each(_jObjEllipse,(id,el)=>{
				let cX=Number($(el).attr("cx"));
				let cY=Number($(el).attr("cy"));
				let cRX=Number($(el).attr("rx"));
				let cRY=Number($(el).attr("ry"));
				let ellipseGraphic=new PIXI.Graphics();
				ellipseGraphic.lineStyle(lineStyle.w,drawColor,1);
				ellipseGraphic.beginFill(drawColor,drawOpacity);
				ellipseGraphic.drawEllipse(cX,cY,cRX,cRY);
				container.addChild(ellipseGraphic);
			})
		}

		function drawPolygon(_jObjPolygon){
			
			let polysCount=0;
			
			$.each(_jObjPolygon,(id,el)=>{
				let positionStr=$(el).attr("points");
				positions=positionStr.split(" ");
				let polyCount=0;
				let polyGraphic=new PIXI.Graphics();
				polyGraphic.lineStyle(lineStyle.w,drawColor,1);
				polyGraphic.beginFill(drawColor,drawOpacity);

				//polyGraphic.lineStyle(lineStyle.w*0.5,0x00ffff,1);
				
				polysCount+=1;
				let startP;
				let lastP=[];
				
				$.each(positions,(id0,el0)=>{
					position=el0.split(",");
					
					if(position.length==1){
						return false;
					}else{
						let x=Number(position[0]);
						let y=Number(position[1]);

						
						if((x || x==0) && (y || y==0)){
							if(polyCount==0){
								polyGraphic.moveTo(x,y);
								startP=[x,y];
								lastP=[x,y];
								console.log()
							}else{
								if(lastP[0]!=x || lastP[1]!=y){
									polyGraphic.lineTo(x,y);
								}else{
								}
								lastP=[x,y];
							}
							polyCount+=1;
						}
					}
				})

				polyGraphic.lineTo(startP[0],startP[1]);
				boundingBoxs.push(polyGraphic.getBounds());

				container.addChild(polyGraphic);
			}

			ANLIZE_SVG.polygonCount+=1;

		}

		function drawPath(_jObjPath){
			
			$.each(_jObjPath,(id,el)=>{
				let d=$(el).attr("d");

				let pathGraphic=new PIXI.Graphics();
				pathGraphic.beginFill(drawColor,1);
				pathGraphic.lineStyle(lineStyle.w,drawColor,1);

				//pathGraphic.lineStyle(lineStyle.w*0.5,0x00ffff,1);
				
				let zCounts=(d.match(/z/g)?d.match(/z/g).length:0;

				let replaceMinus=d.replace(/\-/g,",-");
				let replaceCommand=replaceMinus.replace(/l/g,":l").replace(/L/g,":L").replace(/V/g,":V").replace(/v/g,":v").replace(/H/g,":H").replace(/h/g,":h").replace(/s/g,":s").replace(/S/g,":S").replace(/C/g,":C").replace(/c/g,":c").replace(/z/g,":z");
				let commands=replaceCommand.split(":");
				
				let lastCommand="";
				let lastP2=[];
				let lastPoints=[];
				var x,y;
				let zCount=0;
				let startPosition={x:0,y:0}
				
				$.each(commands,(id0,el0)=>{

					let command=el0.charAt(0);
					var dx1,dy1,dx2,dy2,dx,dy;
					
					if(command == "M"){
						
						let movePoints=el0.replace("M,","").replace("M","").split(",");
						x=Number(movePoints[0]);
						y=Number(movePoints[1]);
						pathGraphic.moveTo(x,y);
						startPosition={x:x,y:y}


						lastP2=[x,y,x,y,x,y];
						
					}else if(command == "C"){
						let movePoints=el0.replace(/C,/,"").replace(/C/,"").split(",");
						$.each(movePoints,(id1,el1)=>{

							switch(id1){
								case 0 : dx1=Number(el1);break;
								case 1 : dy1=Number(el1);break;
								case 2 : dx2=Number(el1);break;
								case 3 : dy2=Number(el1);break;
								case 4 : dx=Number(el1);break;
								case 5 : dy=Number(el1);break;
							}
						})

						pathGraphic.bezierCurveTo(dx1,dy1,dx2,dy2,dx,dy);
						lastP2=[dx1,dy1,dx2,dy2,dx,dy];
					}else if(command == "c"){
						let movePoints=el0.replace(/c,/,"").replace(/c/,"").split(",");
						let el1s=[];
						$.each(movePoints,(id1,el1)=>{

							switch(id1){
								case 0 : dx1=lastP2[4]+Number(el1);break;
								case 1 : dy1=lastP2[5]+Number(el1);break;
								case 2 : dx2=lastP2[4]+Number(el1);break;
								case 3 : dy2=lastP2[5]+Number(el1);break;
								case 4 : dx=lastP2[4]+Number(el1);break;
								case 5 : dy=lastP2[5]+Number(el1);break;
							}

							el1s.push(el1);
							
						})

						if(el1s[0]==0 || el1s[1]==0 || el1s[2]==0 || el1s[3]==0 ){
		
							pathGraphic.lineTo(dx,dy)
						}else{
							pathGraphic.bezierCurveTo(dx1,dy1,dx2,dy2,dx,dy);
						}


						lastP2=[dx1,dy1,dx2,dy2,dx,dy];

					}else if(command == "S"){
						let movePoints=el0.replace(/S,/,"").replace(/S/,"").replace(" ",",").split(",");
						$.each(movePoints,(id1,el1)=>{
							dx1=lastP2[4]-(lastP2[2]-lastP2[4]);
							dy1=lastP2[5]-(lastP2[3]-lastP2[5]);


							if(lastCommand=="s" || lastCommand=="c"){

								switch(id1){
									case 0 :dx2=Number(el1);break;
									case 1 :dy2=Number(el1);break;
									case 2 :dx=Number(el1);break;
									case 3 :dy=Number(el1);break;
								}	
							}else{
								//console.log("YYYY")
								//イラレでは、この状態が存在しない?
								switch(id1){
									case 0 :dx1=dx2=Number(position[0]);dy1=dy2=Number(position[1]);break;
									case 1 :dx=Number(position[0]);dy=Number(position[1]);break;
								}
							}
						})
						pathGraphic.bezierCurveTo(dx1,dy1,dx2,dy2,dx,dy);
						lastP2=[dx1,dy1,dx2,dy2,dx,dy];
						
					}else if(command == "s"){
						let movePoints=el0.replace(/s,/,"").replace(/s/,"").replace(" ",",").split(",");
						dx1=lastP2[4]-(lastP2[2]-lastP2[4]);
						dy1=lastP2[5]-(lastP2[3]-lastP2[5]);

						$.each(movePoints,(id1,el1)=>{
							
							if(lastCommand=="s" || lastCommand=="c"){
								
								switch(id1){
									case 0 :dx2=lastP2[4]+Number(el1);break;
									case 1 :dy2=lastP2[5]+Number(el1);break;
									case 2 :dx=lastP2[4]+Number(el1);break;
									case 3 :dy=lastP2[5]+Number(el1);break;
								}	
							}else{
								//console.log("YYYY")
								//イラレでは、この状態が存在しない?
								switch(id1){
									case 0 :dx1=dx2=lastP2[4]+Number(el1);break;
									case 1 :dy1=dy2=lastP2[4]+Number(el1);break;
									case 2 :dx=lastP2[4]+Number(el1);break;
									case 3 :dy=lastP2[4]+Number(el1);break;
								}
							}
						})

						pathGraphic.bezierCurveTo(dx1,dy1,dx2,dy2,dx,dy);
						lastP2=[dx1,dy1,dx2,dy2,dx,dy];


					}else if(command == "L"){	
						let movePoints=el0.replace(/L,/,"").replace(/L/,"").split(",");
						
						dx=Number(movePoints[0]);
						dy=Number(movePoints[1]);

						pathGraphic.lineTo(dx,dy);
						lastP2=[dx,dy,dx,dy,dx,dy];
						
					}else if(command == "l"){
						let movePoints=el0.replace(/l,/,"").replace(/l/,"").split(",");
						dx=lastP2[4]+Number(movePoints[0]);
						dy=lastP2[5]+Number(movePoints[1]);
						if(dx!=lastP2[4] || dy!=lastP2[5]){
							pathGraphic.lineTo(dx,dy);
						}
						lastP2=[dx,dy,dx,dy,dx,dy];

						
					}else if(command == "H"){
						let movePoints=Number(el0.replace(/H,/,"").replace(/H/,""));
						
						dx=movePoints;
						dy=lastP2[5];
						
						pathGraphic.lineTo(dx,dy);

						lastP2=[dx,dy,dx,dy,dx,dy];

					}else if(command == "h"){
						let movePoints=Number(el0.replace(/h,/,"").replace(/h/,""));
						dx=lastP2[4]+movePoints;
						dy=lastP2[5];
						if(dx!=lastP2[4]){
							pathGraphic.lineTo(dx,dy);
						}
						lastP2=[dx,dy,dx,dy,dx,dy];

					}else if(command == "V"){
						let movePoints=Number(el0.replace(/V,/,"").replace(/V/,""));
						
						dx=lastP2[4];
						dy=movePoints;
						pathGraphic.lineTo(dx,dy);
						lastP2=[dx,dy,dx,dy,dx,dy];

					}else if(command == "v"){
						let movePoints=Number(el0.replace(/v,/,"").replace(/v/,""));
						dx=lastP2[4];
						dy=lastP2[5]+movePoints;
						if(dy!=lastP2[5]){
							pathGraphic.lineTo(dx,dy);
						}
						lastP2=[dx,dy,dx,dy,dx,dy];

					}else if(command == "z"){
							
						pathGraphic.closePath();
						
						
						if(zCounts>1){
							movePoints=el0.split(" ");
							
							$.each(movePoints,(id1,el1)=>{
								if(movePoints[id1].charAt(0)=="M"){

									if(zCount!=0){
										pathGraphic.addHole();
									}

									let Mp=movePoints[id1].replace(/M,/,"").replace(/M/,"").split(",");

									x=Number(Mp[0]);
									y=Number(Mp[1]);
									pathGraphic.moveTo(x,y);

									lastP2=[x,y,x,y,x,y];
											
								}

							})
							if(zCount==zCounts-1){
								//console.log("終了");
								pathGraphic.addHole();

							}
						}
							
						zCount+=1;
					}
					lastCommand=command;
				})

				//pathGraphic.endFill();

				container.addChild(pathGraphic);
				pathGraphic.calculateBounds();
				boundingBoxs.push(pathGraphic.getBounds());	

			})
			
		};
	}
}